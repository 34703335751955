import { Button, PropertyPreview } from "@global";
import { Container, PageWidth } from "@util/standard";
import React, { useEffect, useState } from "react";
import AccountTemplate from "@shared/account/accountTemplate";
import styled from "styled-components";
import { useGetUserChangeRequests } from "@util/hooks";
import { useStore } from "@state/store";
import { SanityProperty } from "@graphql-types";
import { getSanityProperty } from "@util/helper";

const Account = () => {
  const user = useStore(s => s.user);
  //const [orders, setOrders] = useState<Order[]>();
  const [properties, setProperties] = useState<SanityProperty[]>();
  useGetUserChangeRequests();

  useEffect(() => {
    if (properties || !user?.propertyIds) return;

    const fetchData = async () => {
      const properties = await getSanityProperty(user.propertyIds!);
      setProperties(properties);
    };

    fetchData();
  }, [properties]);

  // TODO - Check unused code
  // useEffect(() => {
  //   if (!user) return;
  //   const firestore = firebase.firestore();

  //   const getOrders = async () => {
  //     const orders = await getDocsCollection<Order>(
  //       firestore.collection("orders").where("uid", "==", user.id),
  //     );
  //     if (orders) setOrders(orders);
  //   };

  //   getOrders();
  // }, [user]);

  // useEffect(() => {
  //   if (properties && properties.length == 1) {
  //     navigate(`/account/property/`, { state: { id: properties[0], fetchOnLoad: true } });
  //   }
  // }, [properties]);

  return (
    <AccountTemplate handle="properties">
      <PageWidth>
        <Container margin="0 0 35px" flexFlow="column nowrap">
          {user?.propertyIds?.length && properties ? (
            <PropertyWrapperStyled flexDirection="column">
              {properties.map(property => {
                // const matchedOrder = orders?.find(order =>
                //   order.packages.find(
                //     _package => _package.propertyId?.toString() === property._id,
                //   ),
                // );
                return <PropertyPreview data={property} />; //order={orders && orders[0]}
              })}
            </PropertyWrapperStyled>
          ) : (
            <>
              <Container flexFlow="column nowrap" width="100%" margin="35px 0" alignItems="center">
                <p>
                  {user?.propertyIds?.length
                    ? "Loading properties"
                    : "No orders found. If you are interested in selling, check out our packages"}
                </p>
                {user?.propertyIds?.length ? null : (
                  <Button linkText="Browse Packages" internalSlug="/homesell-go" />
                )}
              </Container>
            </>
          )}
        </Container>
      </PageWidth>
    </AccountTemplate>
  );
};

export default Account;

const PropertyWrapperStyled = styled(Container)`
  gap: 40px;

  .content-wrapper {
    padding: 20px;

    p {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
  }
`;
